export default class ReservationConstants {
  public static readonly REGEXPMOBILE_FOR_CONVERT_KO_010: RegExp = /.*(^010).*([0-9]{4}).*([0-9]{4})$/;
  public static readonly REGEXPMOBILE_FOR_CONVERT_KO_01X: RegExp = /.*(^01[1|6|7|8|9]).*([0-9]{3}).*([0-9]{4})$/;
  public static readonly REGEXP_MOBILE: RegExp = /^(((010){1}-(\d{4})-(\d{4}))|((01[16789]){1}-(\d{3})-(\d{4})))$/;
  public static readonly REGEXP_MOBILE_VALUE: RegExp = /^(((010){1}(\d{4})(\d{4}))|((01[16789]){1}(\d{3})(\d{4})))$/;
  public static readonly REGEXP_MOBILE_MEMBER: RegExp = /.*([0-9]{4})$/;
  public static readonly REGEXP_MOBILE_GLOBAL: RegExp = /^(((821)[0-9]{8,9})|(\+(821)[0-9]{8,9}))$/;
  public static readonly REGEXP_MOBILE_GLOBAL_FORMAT: RegExp = /^\+/;
  public static readonly REGEXP_CAR_NUMBER: RegExp = /^(([0-9]{2,3}[가-힣]{1}[0-9]{4})|([가-힣]{2}[0-9]{2,3}[가-힣]{1}[0-9]{4}))$/;
  public static readonly REGEXP_NAME: RegExp = /^[가-힣a-zA-Z0-9\s]+$/;

  public static readonly SEARCH_USER: string = 'searchUser';
  public static readonly MSG_CODE_REQUIRED_FORMAT: string = 'common.message.exception.requiredFormat';
  public static readonly MSG_CODE_FOR_NAME: string =
    'reservation.label.ReservationBottomForms.visitors.placeholder.name';
  public static readonly MSG_CODE_FOR_NAME_NONEMEMBER: string =
    'reservation.label.ReservationBottomForms.visitors.placeholder.name';
  public static readonly MSG_CODE_FOR_MOBILE_MEMBER: string =
    'reservation.label.ReservationBottomForms.visitors.placeholder.mobileMember';
  public static readonly MSG_CODE_FOR_MOBILE_NOMEMBER: string =
    'reservation.label.ReservationBottomForms.visitors.placeholder.mobileNoMember';
  public static readonly MSG_CODE_FOR_TYPE: string =
    'reservation.label.ReservationBottomForms.visitors.placeholder.type';
  public static readonly MSG_CODE_FOR_COMPANY: string =
    'reservation.label.ReservationBottomForms.visitors.placeholder.company';
  public static readonly MSG_CODE_FOR_NAME_FORMAT: string =
    'reservation.label.ReservationBottomForms.visitors.exception.nameFormat';
  public static readonly MSG_CODE_FOR_MOBILE_FORMAT: string =
    'reservation.label.ReservationBottomForms.visitors.exception.mobileFormat';
  public static readonly MSG_CODE_FOR_MOBILE_FORMAT_MEMBER: string =
    'reservation.label.ReservationBottomForms.visitors.exception.mobileMemberFormat';
  public static readonly MSG_CODE_FOR_MISSING_REQUIRED_FIELD: string =
    'reservation.label.ReservationBottomForms.visitors.exception.missingRequiredField';
  public static readonly MSG_CODE_FOR_MISSING_COMPANY: string =
    'reservation.label.ReservationBottomForms.visitors.exception.missingCompany';

  public static readonly MSG_CODE_FOR_NAME_MIN_LENGTH_FIELD: string =
    'reservation.label.ReservationBottomForms.visitors.exception.minName';

  public static readonly VISIT_CALENDAR_MAX_PERIOD: number = 10000; //Max Period 제한이 느껴지지 않을 정도로 큰 값을 임시로 부여함. 향후 VisitPeriodCalendar 수정 가능성 있음.

  public static readonly NAME_MAX_LENGTH: number = 100;

  public static readonly NAME_MIN_LENGTH: number = 2;

  public static readonly PURPOSE_DETAIL_MAX_LENGTH: number = 200;
}

export interface MemberTypeInfo {
  type: string;
  class: string;
  desc: string;
}

export enum MemberTypeCode {
  MEM = 'MEM',
  EMP = 'EMP',
  NO_MEM = 'NO_MEM',
}
export enum UserTypeEnum {
  LGMEMBER = 'L',
  NONEMEMBER = 'N',
  MEMBER = 'M',
}
export enum ApplyTypeCode {
  SUBSITUTE = 'SUB',
  SELF = 'SELF',
}
export enum UserTypeEnumForClass {
  LGMEMBER = 'lg',
  NONEMEMBER = 'none-member',
  MEMBER = 'member',
  ME = 'me',
}
export enum VisitorAddInputTypeEnum {
  ADD = 'add',
  SEARCH = 'search',
}
export enum VisitorSearchConditionTypeEnum {
  NAME = 'name',
  MOBILE = 'mobile',
  USERTYPE = 'userType',
  USERCOMPANY = 'userCompany',
}

export enum PurposeTypeEnum {
  SESSION = 'C0042',
  PROJECT = 'C0043',
  FACILITY = 'C0044',
  ITEM = 'C0045',
  ETC = 'C0046',
}

interface PurposeType {
  C0042: string;
  C0043: string;
  C0044: string;
  C0045: string;
  C0046: string;
}

export const PurposeTypeDesc: PurposeType = {
  C0042: 'reservation.label.ReservationBottomForms.purpose.buttons.C0042',
  C0043: 'reservation.label.ReservationBottomForms.purpose.buttons.C0043',
  C0044: 'reservation.label.ReservationBottomForms.purpose.buttons.C0044',
  C0045: 'reservation.label.ReservationBottomForms.purpose.buttons.C0045',
  C0046: 'reservation.label.ReservationBottomForms.purpose.buttons.C0046',
};

export enum ApprovalStatusEnum {
  IP016001 = 'IP016001', // 임시저장
  IP016002 = 'IP016002', // 신청
  IP016003 = 'IP016003', // 승인
  IP016004 = 'IP016004', // 부결
  IP016005 = 'IP016005', // 취소
}

interface ApprovalStatusDescType {
  IP016001: string;
  IP016002: string;
  IP016003: string;
  IP016004: string;
  IP016005: string;
}

export const ApprovalStatusDesc: ApprovalStatusDescType = {
  IP016001: 'reservation.label.ReservationCompleteModal.approval.IP016001',
  IP016002: 'reservation.label.ReservationCompleteModal.approval.IP016002',
  IP016003: 'reservation.label.ReservationCompleteModal.approval.IP016003',
  IP016004: 'reservation.label.ReservationCompleteModal.approval.IP016004',
  IP016005: 'reservation.label.ReservationCompleteModal.approval.IP016005',
};

export const ApproveModalTitle: ApprovalStatusDescType = {
  IP016001: 'reservation.label.ModalVisitToTarget.body.title.IP016001',
  IP016002: 'reservation.label.ModalVisitToTarget.body.title.IP016002',
  IP016003: 'reservation.label.ModalVisitToTarget.body.title.IP016003',
  IP016004: 'reservation.label.ModalVisitToTarget.body.title.IP016004',
  IP016005: 'reservation.label.ModalVisitToTarget.body.title.IP016005',
};

export const ImportedGoodsTypeEnum: ImportedGoodsTypeEnum = {
  C0052: '노트북',
  C0053: '카메라',
  C0054: 'USB메모리',
  C0055: '외장형HDD',
  C0056: 'CD-RW/DVD-RW',
  C0057: '캠코더',
  C0058: '기타',
  GM003001: '시료/Mock-Up',
  C0068: '서버',
  C0069: '스토리지',
  C0070: 'N/W 장비',
  C0071: '파트',
};

interface ImportedGoodsTypeEnum {
  C0052: string;
  C0053: string;
  C0054: string;
  C0055: string;
  C0056: string;
  C0057: string;
  C0058: string;
  GM003001: string;
  C0068: string;
  C0069: string;
  C0070: string;
  C0071: string;
}

export enum ImportedGoodsCodeEnum {
  NOTEBOOK = 'C0052',
  CAMERA = 'C0053',
  USB = 'C0054',
  HDD = 'C0055',
  DISK_RW = 'C0056',
  CAMCORDER = 'C0057',
  MOCK = 'GM003001',
  ETC = 'C0058',
  SERVER = 'C0068',
  STORAGE = 'C0069',
  NETWORK = 'C0070',
  PART = 'C0071',
}

export enum PortSealExceptCodeEnum {
  USB = 'C0063',
  LAN = 'C0064',
  SD_CARD = 'C0065',
  CAMERA = 'C0066',
  ETC = 'C0067',
}

export enum CalendarTypeEnum {
  ONEDAY = 'oneday',
  RANGE = 'range',
}

export enum localeEnum {
  KO = 'ko',
  EN = 'en',
}

export enum TermsTypeCodeEnum {
  THIRD = 'THIRD',
  SERVICE = 'SERVICE',
}

export enum DateFormat {
  MONTH_AND_DAY_DISP = 'M. D',
  DATE_DISP = 'YYYY. M. D',
  DATE_DISP_ADMIN = 'YYYY.MM.DD',
  DATE_IF = 'YYYYMMDD',
  DATETIME_DISP_ADMIN = 'YYYY.MM.DD HH:mm:ss',
  DATETIME_IF = 'YYYYMMDDHHmmss',
}
