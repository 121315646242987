import React, { ReactElement, useState, useEffect, useContext } from 'react';
import './Footer.scss';
import { Row, Col, Image, Button, DropdownButton, Dropdown, Container } from 'react-bootstrap';
import DOMPurify from 'dompurify';
import CNSlogoImage from '../icons/lg.svg';
import SinglexLogoImage from '../icons/logo_vrp_kr.png';
import SinglexLogoImageEng from '../icons/logo_vrp_en.png';
import { useTranslation } from 'react-i18next';
import TermsAcceptionModal from '../pages/member/memberRegistration/component/TermsAcceptionModal';
import PrivacyPolicyModal from '../pages/member/memberRegistration/component/PrivacyPolicyModal';
import TermService from '../pages/member/services/TermService';
import MemberRegistrationConstants from '../pages/member/model/MemberRegistrationConstants';
import TenantInfo from '../pages/member/model/TenantInfo';
import { TermsTypeCodeEnum } from '../pages/reservation/model/ReservationConstants';
import { useHistory, useLocation } from 'react-router-dom';
import SystemNoticeModal from '../pages/reservation/components/SystemNoticeModal';
import WorkplaceFAQModal from '../pages/reservation/components/WorkplaceFAQModal';
import SystemNoticeModifyModal from '../pages/reservation/components/SystemNoticeModifyModal';
import InvitationModal from '../pages/member/memberRegistration/component/InvitationModal';
import { Language } from '../model/Language';
import { Channel } from '../model/Channel';
import ConfirmModal from '../pages/reservation/components/ConfirmModal';
import { LocalStorageKey } from '../model/Language';
import {
  NoticeList,
  NoticeModifyMode,
  RequestUpdateNoticeList,
  RequestCreateNoticeList,
  FAQModifyMode,
  RequestUpdateFaqList,
  FaqList,
  RequestCreateFaqList,
} from '../pages/reservation/model/Workplace';
import WorkplaceService from '../pages/info/services/WorkplaceService';
import { AuthContext } from '../App';
import { ActionType } from '../reducer/actions';
import FAQModifyModal from '../pages/reservation/components/FAQModifyModal';
import { User } from '../pages/reservation/model/User';
import { reservationDetailUtil } from '../pages/reservation/utils/ReservationDetailUtil';
import singlexBanner from '../icons/singlex_banner.png';
import buildConfig from '../build-env';
import ideaTownBanner from '../icons/ideaTown_banner.png';
import MemberInfoService from '../pages/member/services/MemberInfoService';

export interface FooterI {
  handleChangeLanguage?: Function;
}

const Footer: React.FC<FooterI> = (props: FooterI): ReactElement => {
  const { t, i18n } = useTranslation();
  const [showTermsAcceptionModal, setShowTermsAcceptionModal] = useState<boolean>(false);
  const [showPersonalInfoTermModal, setShowPersonalInfoTermModal] = useState<boolean>(false);
  const [showFAQModal, setShowFAQModal] = useState<boolean>(false);
  const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false);
  const [confirmModalMessage, setConfirmModalMessage] = useState<string>('');
  const [showSystemNoticeModifyModal, setShowSystemNoticeModifyModal] = useState<boolean>(false);
  const [showFAQModifyModal, setShowFAQModifyModal] = useState<boolean>(false);
  const [showInvitationModal, setShowInvitationModal] = useState<boolean>(false);
  const [modifyTargetNotice, setModifyTargetNotice] = useState<NoticeList | null>(null);
  const [modifyTargetFAQ, setModifyTargetFAQ] = useState<FaqList | null>(null);
  const [localLanguage, setLocalLanguage] = useState<string>();
  const [sessionUser, setSessionUser] = useState<User | null>(null);
  const [tenantId, setTenantId] = useState<string>('');
  const memberTypeCode = sessionStorage.getItem('memberTypeCode') || MemberRegistrationConstants.NON_MEMBER_TYPE_CODE;
  const history = useHistory();
  const location = useLocation();
  const termsService = new TermService();
  const workplaceService = new WorkplaceService();
  const memberInfoService = new MemberInfoService();
  const [showSystemNoticeModal, setShowSystemNoticeModal] = useState<boolean>(false);
  const logoImage = window.location.hostname.includes('singlex')
    ? localStorage.getItem('lang') === 'ko'
      ? SinglexLogoImage
      : SinglexLogoImageEng
    : CNSlogoImage;
  const { state, dispatch } = useContext(AuthContext);
  const hideModals = () => {
    history.goBack();
    setShowPersonalInfoTermModal(false);
    setShowTermsAcceptionModal(false);
    setShowSystemNoticeModal(false);
    setShowSystemNoticeModifyModal(false);
    setShowFAQModal(false);
    setShowInvitationModal(false);
  };
  const showTermsAcception = () => {
    history.push(location.hash + '#termsAcceptionModal');
    setShowTermsAcceptionModal(true);
  };
  const showPersonalInfoTerm = () => {
    history.push(location.hash + '#personalInfoTermModal');
    setShowPersonalInfoTermModal(true);
  };
  const showSystemNotice = () => {
    history.push(location.hash + '#systemNoticeModal');
    setShowSystemNoticeModal(true);
  };

  const showFAQ = () => {
    history.push(location.hash + '#FAQModal');
    setShowFAQModal(true);
  };

  const showInvitation = () => {
    history.push(location.hash + '#InvitationModal');
    setShowInvitationModal(true);
  };

  const loadSocialWindow = () => {
    window.open('http://www.lg.co.kr/media/social', '_sub');
  };
  const setLoading = (_isLoading: boolean) => {
    dispatch({ type: ActionType.LOADING, loading: { isLoading: _isLoading } });
  };
  /* eslint-disable */
  useEffect(() => {
    setLoading(true);
    const getSessionInfo = async () => {
      return await reservationDetailUtil.getSessionUserInfo();
    };
    getSessionInfo()
      .then((res: User) => {
        const user: User = res;
        setSessionUser(user);
        getTenantInfo(user.id);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {

    if (!location.hash.includes('#personalInfoTermModal')) {
      setShowPersonalInfoTermModal(false);
    } // remove hash modal
    if (!location.hash.includes('#termsAcceptionModal')) {
      setShowTermsAcceptionModal(false);
    }
    if (!location.hash.includes('#systemNoticeModal')) {
      setShowSystemNoticeModal(false);
      setShowSystemNoticeModifyModal(false);
    }
    if (!location.hash.includes('#FAQModal')) {
      setShowFAQModal(false);
    }
    if (!location.hash.includes('#confirmModal')) {
      setShowConfirmModal(false);
    }
    if (!location.hash.includes('#InvitationModal')) {
      setShowInvitationModal(false);
    }
  }, [location]);

  /* eslint-enable */
  const handleCloseConfirmModal = () => {
    history.goBack();
  };
  const handleSystemNoticeModify = async (target: NoticeList | null, mode: NoticeModifyMode) => {
    setModifyTargetNotice(target);

    switch (mode) {
      case NoticeModifyMode.EDIT:
      case NoticeModifyMode.CREATE:
        setShowSystemNoticeModal(false);
        setShowSystemNoticeModifyModal(true);
        break;
      case NoticeModifyMode.IMPORTANCE:
        if (target) {
          setLoading(true);
          setShowSystemNoticeModal(false);
          const request: RequestUpdateNoticeList = {
            noticeId: target.noticeId,
            importanceYn: target.importanceYn === 'Y' ? 'N' : 'Y',
          };
          if (request.importanceYn === 'Y') {
            request.useYn = 'Y';
          }
          await workplaceService.updateNotice(request);
          setShowSystemNoticeModal(true);
          setLoading(false);
        }
        break;
      case NoticeModifyMode.POPUP:
        if (target) {
          setLoading(true);
          setShowSystemNoticeModal(false);
          const request: RequestUpdateNoticeList = {
            noticeId: target.noticeId,
            popupYn: target.popupYn === 'Y' ? 'N' : 'Y',
          };
          if (request.popupYn === 'Y') {
            request.useYn = 'Y';
          }

          await workplaceService.updateNotice(request);
          setShowSystemNoticeModal(true);
          setLoading(false);
        }
        break;
      case NoticeModifyMode.VISIBLE:
        setLoading(true);
        setShowSystemNoticeModal(false);
        if (target) {
          const request: RequestUpdateNoticeList = {
            noticeId: target.noticeId,
            useYn: target.useYn === 'Y' ? 'N' : 'Y',
          };

          if (request.useYn === 'N') {
            request.popupYn = 'N';
          }
          await workplaceService.updateNotice(request);
          setShowSystemNoticeModal(true);
          setLoading(false);
        }
        break;
    }
  };
  const handleCloseSystemNoticeModifyModal = async (notice: NoticeList) => {
    if (notice && notice.noticeId !== '') {
      const request: RequestUpdateNoticeList = notice;
      if (notice.useYn !== 'Y') {
        request.popupYn = 'N';
        request.importanceYn = 'N';
      }
      if (notice.fileId === '' && notice.fileSize > 0) {
        request.noticeFile = {
          fileExtension: notice.fileExtension,
          fileName: notice.fileName,
          fileOriginalName: notice.fileOriginalName,
          filePath: notice.filePath,
          fileSize: notice.fileSize,
        };
      }
      const result = await workplaceService.updateNotice(request);
    } else if (notice) {
      const request: RequestCreateNoticeList = notice;
      if (notice.useYn !== 'Y') {
        request.popupYn = 'N';
        request.importanceYn = 'N';
      }
      if (notice.fileId === '' && notice.fileSize > 0) {
        request.noticeFile = {
          fileExtension: notice.fileExtension,
          fileName: notice.fileName,
          fileOriginalName: notice.fileOriginalName,
          filePath: notice.filePath,
          fileSize: notice.fileSize,
        };
      }
      const result = await workplaceService.createNotice(request);
    }
    setShowSystemNoticeModifyModal(false);
    setShowSystemNoticeModal(true);
  };
  const handleFAQModify = async (target: FaqList | null, mode: FAQModifyMode) => {
    setModifyTargetFAQ(target);

    switch (mode) {
      case FAQModifyMode.EDIT:
      case FAQModifyMode.CREATE:
        setShowFAQModal(false);
        setShowFAQModifyModal(true);
        break;
      case FAQModifyMode.VISIBLE:
        setLoading(true);
        setShowFAQModal(false);
        if (target) {
          const request: RequestUpdateFaqList = {
            faqId: target.faqId,
            useYn: target.useYn === 'Y' ? 'N' : 'Y',
          };

          await workplaceService.updateFaq(request);
          setShowFAQModal(true);
          setLoading(false);
        }
        break;
    }
  };
  const handleCloseFaqModifyModal = async (faq: FaqList) => {
    if (faq && faq.faqId !== '') {
      const request: RequestUpdateFaqList = faq;

      if (faq.fileId === '' && faq.fileSize > 0) {
        request.faqFile = {
          fileExtension: faq.fileExtension,
          fileName: faq.fileName,
          fileOriginalName: faq.fileOriginalName,
          filePath: faq.filePath,
          fileSize: faq.fileSize,
        };
      }
      const result = await workplaceService.updateFaq(request);
    } else if (faq) {
      const request: RequestCreateFaqList = faq;

      if (faq.fileId === '' && faq.fileSize > 0) {
        request.faqFile = {
          fileExtension: faq.fileExtension,
          fileName: faq.fileName,
          fileOriginalName: faq.fileOriginalName,
          filePath: faq.filePath,
          fileSize: faq.fileSize,
        };
      }
      const result = await workplaceService.createFaq(request);
    }
    setShowFAQModifyModal(false);
    setShowFAQModal(true);
  };
  const onConfirm = () => {
    setShowConfirmModal(false);
    history.goBack();
    if (localLanguage) {
      i18n.changeLanguage(localLanguage, () => {
        localStorage.setItem('lang', localLanguage);
      });
      props.handleChangeLanguage && props.handleChangeLanguage(localLanguage);
      window.location.assign('/reservation');
    }
  };
  const onSelectLanguage = (eventKey: any, event: Record<string, any>): void => {
    if (localStorage.getItem(LocalStorageKey.LANG) !== eventKey) {
      setLocalLanguage(eventKey);
      setConfirmModalMessage(t('reservation.label.ConfirmModal.clearWorkplace.message'));
      history.push(location.hash + '#confirmModal');
      setShowConfirmModal(true);
    }
  };

  const onSelectChannel = (index: number): void => {
    const url = Object.values(Channel)[index];
    if (window.ReactNativeWebView) {
      window.ReactNativeWebView.postMessage(url);
    } else {
      window.open(url, '_sub');
    }
  };

  const getTenantInfo = async (memberId: string) => {
    const tenantInfo: TenantInfo = await memberInfoService.getTenantInfo(memberId);

    if (tenantInfo) {
      setTenantId(tenantInfo.tenantId);
    }
  };

  return (
    <div id="Footer">
      {showConfirmModal && (
        <ConfirmModal
          message={confirmModalMessage}
          show={showConfirmModal}
          onConfirm={onConfirm}
          handleClose={handleCloseConfirmModal}
        />
      )}
      <footer>
        <Row>
          <Col sm={7}>
            <Row className={'footer-links'}>
              <Col sm={12} className="d-inline-block">
                <a
                  id="showTermsAcception"
                  data-testid="showTermsAcception"
                  href="#"
                  onClick={e => {
                    e.preventDefault();
                    showTermsAcception();
                  }}
                >
                  {t('member.label.LoginFooter.serviceTerm')}
                </a>
                <a className="splitter"> • </a>
                <a
                  id="showPersonalInfoTerm"
                  data-testid="showPersonalInfoTerm"
                  href="#"
                  className="danger"
                  onClick={e => {
                    e.preventDefault();
                    showPersonalInfoTerm();
                  }}
                >
                  {t('member.label.LoginFooter.personalInfoTerm')}
                </a>
                <a className="splitter"> • </a>
                <a
                  id="showSystemNotice"
                  data-testid="showSystemNotice"
                  href="#"
                  onClick={e => {
                    e.preventDefault();
                    showSystemNotice();
                  }}
                >
                  {t('reservation.label.Footer.notice')}
                </a>
                <a className="splitter"> • </a>
                <a
                  id="showFAQ"
                  data-testid="showFAQ"
                  href="#"
                  onClick={e => {
                    e.preventDefault();
                    showFAQ();
                  }}
                >
                  {t('member.label.LoginFooter.faq')}
                </a>
                {sessionUser?.memberTypeCode === 'EMP' && (
                  <a>
                    <a className="splitter"> • </a>
                    <a
                      id="showInvitation"
                      data-testid="showInvitation"
                      href="#"
                      className="danger"
                      onClick={e => {
                        e.preventDefault();
                        showInvitation();
                      }}
                    >
                      {t('member.label.LoginFooter.invitation')}
                    </a>
                  </a>
                )}
              </Col>
            </Row>
          </Col>
          <Col className={'languageCol'}>
            <DropdownButton
              id="select-language"
              className="select-language"
              title={Language[localStorage.getItem(LocalStorageKey.LANG)?.toString() || 'ko']}
            >
              {Object.keys(Language).map((key, index) => {
                return (
                  <Dropdown.Item
                    id={'select-language-item'}
                    onSelect={onSelectLanguage}
                    className="select-language-item"
                    key={index}
                    eventKey={key}
                    data-testid="select-language-item"
                  >
                    {Language[key]}
                  </Dropdown.Item>
                );
              })}
            </DropdownButton>
          </Col>
          <Col className="socialCol">
            <DropdownButton id="select-channel" className="select-channel" title={`Family Site`}>
              {Object.keys(Channel).map((key, index) => {
                return (
                  <Dropdown.Item
                    id={'select-channel-item'}
                    onSelect={e => onSelectChannel(index)}
                    key={index}
                    eventKey={key}
                    data-testid="select-channel-item"
                  >
                    {t(`reservation.label.Footer.channel.${key}`)}{' '}
                  </Dropdown.Item>
                );
              })}
            </DropdownButton>
          </Col>
        </Row>
        <Row className="siteManageInfo">
          <span
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(
                t('reservation.label.Footer.callNo', { interpolation: { escapeValue: false } })
              ),
            }}
          ></span>
          <span
            className="center"
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(
                t('reservation.label.Footer.email', { interpolation: { escapeValue: false } })
              ),
            }}
          ></span>
        </Row>
        <Row className="logoRow">
          <Image src={logoImage} className={'cns-logo-image'} id={logoImage === CNSlogoImage ? '' : 'singlexLogo'} />
          <Col className="bannerCol">
            <a
              href={
                buildConfig.adminAccessAvailable && tenantId
                  ? `https://www.singlex.com/sso/${tenantId}?url=/ideatown/ideafeed/edit`
                  : `https://www.singlex.com/ideatown/ideafeed/edit`
              }
              target="_blank"
            >
              <Image src={ideaTownBanner} className="singlexBanner" />
            </a>
            <a
              href={
                buildConfig.adminAccessAvailable && tenantId
                  ? `https://www.singlex.com/sso/${tenantId}?url=/app/support/inquiry/insert?locale=${
                      localStorage.getItem('lang') ? (localStorage.getItem('lang') as string) : 'ko'
                    }`
                  : `https://www.singlex.com/app/support/inquiry/insert?locale=${
                      localStorage.getItem('lang') ? (localStorage.getItem('lang') as string) : 'ko'
                    }`
              }
              target="_blank"
            >
              <Image src={singlexBanner} className="singlexBanner" />
            </a>
          </Col>
        </Row>
      </footer>
      {showTermsAcceptionModal && (
        <TermsAcceptionModal
          term={undefined}
          termService={termsService}
          termsTypeCode={TermsTypeCodeEnum.SERVICE}
          memberTypeCode={memberTypeCode}
          onHide={hideModals}
        />
      )}
      {showPersonalInfoTermModal && <PrivacyPolicyModal onHide={hideModals} />}
      {showSystemNoticeModal && (
        <SystemNoticeModal onHide={hideModals} onModifyNotice={handleSystemNoticeModify} sessionUser={sessionUser} />
      )}
      {showSystemNoticeModifyModal && (
        <SystemNoticeModifyModal notice={modifyTargetNotice} onHide={handleCloseSystemNoticeModifyModal} />
      )}
      {showFAQModal && (
        <WorkplaceFAQModal onHide={hideModals} onModifyFAQ={handleFAQModify} sessionUser={sessionUser} />
      )}
      {showFAQModifyModal && <FAQModifyModal faq={modifyTargetFAQ} onHide={handleCloseFaqModifyModal} />}
      {showInvitationModal && <InvitationModal onHide={hideModals} />}
    </div>
  );
};

export default Footer;
