import React, { ReactElement, useState, useEffect, useContext } from 'react';
import {
  Form,
  InputGroup,
  Image,
  ButtonToolbar,
  Button,
  Dropdown,
  ToggleButtonGroup,
  ToggleButton,
} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Visitor, SearchUser } from '../model/ReservationTypes';
import { User } from '../model/User';
import { ReservationBottomVisitor } from './ReservationBottomVisitor';
import { CommonCode } from '../model/Code';
import { reservationDetailUtil } from '../utils/ReservationDetailUtil';
import { getNullToEmpty, getArrIndexStringValue } from '../utils/util';
import ReservationConstants, {
  UserTypeEnum,
  VisitorSearchConditionTypeEnum,
  MemberTypeCode,
  TermsTypeCodeEnum,
  ImportedGoodsCodeEnum,
  ApprovalStatusEnum,
} from '../model/ReservationConstants';
import './ReservationBottomForms.scss';
import searchBlack16Icon from '../../../icons/search_black-16px.svg';
import VisitorDetailInfoModal from './VisitorDetailInfoModal';
import WorkplaceService from '../services/WorkplaceService';
import MemberService from '../services/MemberService';
import InterfaceService from '../services/InterfaceService';
import { PolicyCompleteSummary } from '../model/Workplace';
import TermService from '../../member/services/TermService';
import TermsInfo from '../../member/model/TermsInfo';
import TermsAcceptionModal from '../../member/memberRegistration/component/TermsAcceptionModal';
import CommonCodeService from '../services/CommonCodeService';
import ReservationBottomButton from './ReservationBottomButton';
import close24Icon from '../../../icons/close-24px.svg';
import enter24Icon from '../../../icons/subdirectory_arrow_left-24px.svg';
import InputMask from 'react-input-mask';
import { useHistory } from 'react-router-dom';
import ReservationEduAndAgreementModal from '../components/ReservationEduAndAgreementModal';
import { PolicyContentsModel, EntryAreaList } from '../model/Workplace';
import { WorkplaceVisitInfo } from '../model/ReservationTypes';
import ReservationBottomForNoneMember from './ReservationBottomForNoneMember';
import { AuthContext } from '../../../App';
import { ActionType } from '../../../reducer/actions';
import uploadIcon from '../../../icons/upload.svg';
import ExcelUploadModal from './ExcelUploadModal';
import OutsideClickHandler from 'react-outside-click-handler';

export const ReservationBottomForms: React.FC<any> = (formProps: any): ReactElement => {
  const { dispatch } = useContext(AuthContext);
  const workplace = formProps.values.workplace;
  const [maxVisitor, setMaxVisitor] = useState<number>(50);
  const sessionUserInfo = formProps.sessionUser;
  const checkIsSubmited: Function = formProps.checkIsSubmited;
  const { t } = useTranslation();
  const workplaceService = new WorkplaceService();
  const memberService = new MemberService();
  const interfaceService = new InterfaceService();
  const termService = new TermService();
  const commonCodeService = formProps.commonCodeService;
  const commonCodeMap = formProps.commonCodeMap;
  const initCondition: SearchUser = {
    name: '',
    mobile: '',
    position: '',
    company: '',
  };

  const memberTypeExceptMe: CommonCode[] = [
    {
      codeId: 'L',
      codeName: 'LG직원',
    },
    {
      codeId: 'M',
      codeName: '회원',
    },
    {
      codeId: 'N',
      codeName: '비회원',
    },
  ];

  const [showSearchUser, setShowSearchUser] = useState<boolean>(false);
  const [showNoSearchUser, setShowNoSearchUser] = useState<boolean>(false);
  const [showPurposeMore, setShowPurposeMore] = useState<boolean>(false);
  const [amIVisitor, setAmIVisitor] = useState<boolean>(false);
  const [searchUserCond, setSearchUserCond] = useState<SearchUser>({
    ...initCondition,
  });
  const [visitorPhone, setVisitorPhone] = useState<string>('');
  const [searchUserType, setSearchUserType] = useState<string>(getArrIndexStringValue(memberTypeExceptMe, 0, 'codeId'));
  const [searchedUsers, setSearchedUsers] = useState<User[]>();
  const [purposeMore, setPurposeMore] = useState<string>();
  const [showTermForNoneMember, setShowTermForNoneMember] = useState<boolean>(false);
  const [visitorDetailInfo, setVisitorDetailInfo] = useState<Visitor | null>(null);
  const [visitorIndex, setVisitorIndex] = useState<number>(0);
  const [availableGoodsList, setAvailableGoodsList] = useState<CommonCode[]>([]);
  const [visitPurposeList, setVisitPurposeList] = useState<CommonCode[]>([]);
  const [portSealExceptCodeList, setPortSealExceptCodeList] = useState<CommonCode[]>([]);
  const [visitorInfoMessage, setVisitorInfoMessage] = useState<string>('');
  const [showThirdTerms, setShowThirdTerms] = useState<boolean>(false);
  const [thirdTerms, setThirdTerms] = useState<TermsInfo | undefined>(undefined);

  const [showSearchEnterButton, setShowSearchEnterButton] = useState<boolean>(false);
  const [showSearchClearButton, setShowSearchClearButton] = useState<boolean>(false);

  const [visitToPhoneMask, setVisitToPhoneMask] = useState<string>('9999');
  const history = useHistory();
  const [showEduAndAgreementModal, setShowEduAndAgreementModal] = useState<boolean>(false);
  const [policyContentsList, setPolicyContentsList] = useState<PolicyContentsModel[]>([] as PolicyContentsModel[]);
  const [selectedPolicyContent, setSelectedPolicyContent] = useState<PolicyContentsModel>({} as PolicyContentsModel);

  const [isShowExcelUpload, setIsShowExcelUpload] = useState<boolean>(false);
  const [policyCompleteState, setPolicyCompleteState] = useState<{
    safeEducationComplete?: string;
    securityEducationComplete?: string;
    safeAgreementComplete?: string;
    securityAgreementComplete?: string;
  } | null>(null);

  const [entryAreaList, setEntryAreaList] = useState<EntryAreaList[]>([]);

  const setLoading = (_isLoading: boolean) => {
    dispatch({ type: ActionType.LOADING, loading: { isLoading: _isLoading } });
  };
  const setWorkplaceLoading = (_isLoading: boolean) => {
    dispatch({ type: ActionType.LOADING, loading: { isWorkplaceLoading: _isLoading } });
  };

  const getCommonCode = (groupCodeId: string) => {
    if (commonCodeMap) {
      return commonCodeMap.get(groupCodeId);
    }
  };
  const [visitorsTitle, setVisitorsTitle] = useState<string>(
    t('reservation.label.ReservationBottomForms.visitors.title.member')
  );
  const [handleUserTitle, setHandleUserTitle] = useState<string>(
    t('reservation.label.ReservationBottomForms.visitors.addNoneMember')
  );
  const [visitorsSearchNoResultMessage, setVisitorsSearchNoResultMessage] = useState<string>(
    t('reservation.label.ReservationBottomForms.visitors.noResult')
  );

  const deleteVisitor = (index: number) => {
    if (!workplace) {
      return;
    }
    const _visitors: Visitor[] = formProps.values.visitors.filter((visitor: Visitor, inx: number) => {
      return index !== inx;
    });
    formProps.setFieldValue('visitors', _visitors, checkIsSubmited(formProps));
    if (index === 0 && sessionUserInfo.type !== UserTypeEnum.NONEMEMBER) {
      formProps.setFieldValue('isSessionVisitorDisabled', true);
    }
  };

  const searchUsers = async () => {
    formProps.setFieldError('visitors', undefined);
    if (!searchUserCond || (!searchUserCond.name && !searchUserCond.mobile)) {
      return;
    } else if (searchUserCond.name.length < ReservationConstants.NAME_MIN_LENGTH) {
      setShowSearchClearButton(true);
      setShowSearchEnterButton(false);
      setShowNoSearchUser(false);
      setShowSearchUser(false);
      formProps.setFieldError(
        ReservationConstants.SEARCH_USER,
        t(ReservationConstants.MSG_CODE_FOR_NAME_MIN_LENGTH_FIELD)
      );
    } else {
      if (!searchUserCond.name || !searchUserCond.mobile) {
        setShowSearchClearButton(true);
        setShowSearchEnterButton(false);
        setShowNoSearchUser(false);
        setShowSearchUser(false);
        formProps.setFieldError(
          ReservationConstants.SEARCH_USER,
          t(ReservationConstants.MSG_CODE_FOR_MISSING_REQUIRED_FIELD)
        );

        return;
      } else if (searchUserCond.name) {
        if (!ReservationConstants.REGEXP_NAME.test(searchUserCond.name)) {
          setShowSearchClearButton(true);
          setShowSearchEnterButton(false);
          setShowNoSearchUser(false);
          setShowSearchUser(false);
          formProps.setFieldError(ReservationConstants.SEARCH_USER, t(ReservationConstants.MSG_CODE_FOR_NAME_FORMAT));
          return;
        } else if (searchUserCond.name.length < ReservationConstants.NAME_MIN_LENGTH) {
          setShowSearchClearButton(true);
          setShowSearchEnterButton(false);
          setShowNoSearchUser(false);
          setShowSearchUser(false);
          formProps.setFieldError(ReservationConstants.SEARCH_USER, t('member.message.frontEnd.memberName.common'));
          return;
        }
      } else if (searchUserCond.mobile) {
        if (
          searchUserType !== UserTypeEnum.NONEMEMBER &&
          !ReservationConstants.REGEXP_MOBILE_MEMBER.test(searchUserCond.mobile)
        ) {
          setShowSearchClearButton(true);
          setShowSearchEnterButton(false);
          setShowNoSearchUser(false);
          setShowSearchUser(false);
          formProps.setFieldError(
            ReservationConstants.SEARCH_USER,
            t(ReservationConstants.MSG_CODE_FOR_MOBILE_FORMAT_MEMBER)
          );
          return;
        } else if (
          searchUserType === UserTypeEnum.NONEMEMBER &&
          !ReservationConstants.REGEXP_MOBILE.test(searchUserCond.mobile)
        ) {
          setShowSearchClearButton(true);
          setShowSearchEnterButton(false);
          setShowNoSearchUser(false);
          setShowSearchUser(false);
          formProps.setFieldError(ReservationConstants.SEARCH_USER, t(ReservationConstants.MSG_CODE_FOR_MOBILE_FORMAT));
          return;
        }
      }
      if (searchUserType === UserTypeEnum.NONEMEMBER) {
        const addUserInfo: User = {
          companyCode: '',
          email: '',
          name: searchUserCond.name.trim(),
          mobile: searchUserCond.mobile ? getNullToEmpty(searchUserCond.mobile.replace(/-/gi, '')) : '',
          position: getNullToEmpty(searchUserCond.position),
          companyName: getNullToEmpty(searchUserCond.company),
          dept: '',
          type: UserTypeEnum.NONEMEMBER,
          memberTypeCode: MemberTypeCode.NO_MEM,
          id: '',
          isValid: true,
        };

        if (addUserInfo.companyName === '') {
          setShowSearchClearButton(true);
          setShowSearchEnterButton(false);
          setShowNoSearchUser(false);
          setShowSearchUser(false);
          formProps.setFieldError(
            ReservationConstants.SEARCH_USER,
            t(ReservationConstants.MSG_CODE_FOR_MISSING_COMPANY)
          );
          return;
        }

        addUserNonMember(addUserInfo);
      } else {
        if (
          formProps.errors &&
          Object.prototype.hasOwnProperty.call(formProps.errors, ReservationConstants.SEARCH_USER)
        ) {
          setShowSearchClearButton(true);
          setShowSearchEnterButton(false);
          formProps.setFieldError(ReservationConstants.SEARCH_USER, undefined);
        }

        setLoading(true);
        const searchUsers: User[] = await memberService.getSearchUsers(formProps.values.visitors, searchUserCond);
        setLoading(false);

        setSearchedUsers(searchUsers);
        if (searchUsers.length === 1) {
          addUser(searchUsers[0], false);
          setShowSearchUser(false);
        } else if (searchUsers.length > 1) {
          setShowNoSearchUser(false);
          setShowSearchUser(true);
        } else {
          setShowSearchClearButton(true);
          setShowSearchEnterButton(false);
          setShowSearchUser(false);
          setShowNoSearchUser(true);
        }
      }
    }
  };

  const checkInputGroups = (type: string): boolean => {
    let result = false;
    if (searchUserType !== UserTypeEnum.NONEMEMBER) {
      if (type === VisitorSearchConditionTypeEnum.NAME || type === VisitorSearchConditionTypeEnum.MOBILE) {
        result = true;
      }
    } else {
      result = true;
    }
    return result;
  };

  const addUserBySearch = (id: string) => {
    const aUser: any | undefined =
      searchedUsers &&
      searchedUsers.find((user: User) => {
        return user.id === id;
      });

    setShowSearchUser(false);
    addUser(aUser, false);
  };

  const addUserNonMember = (aUser: User) => {
    addUser(aUser, false);
  };

  const addUser = async (aUser: User, isSessionUser: boolean) => {
    if (
      !isSessionUser &&
      formProps.values.visitors &&
      formProps.values.visitors.length > 0 &&
      formProps.values.visitors.findIndex(
        (visitor: Visitor) =>
          aUser.name.trim() === visitor.name.trim() &&
          aUser.mobile.replace(/-/gi, '') === visitor.applyMemberMobilePhone
      ) > -1
    ) {
      setShowSearchClearButton(true);
      setShowSearchEnterButton(false);
      setShowNoSearchUser(false);
      setShowSearchUser(false);
      formProps.setFieldError(
        ReservationConstants.SEARCH_USER,
        t('reservation.label.ReservationBottomForms.visitors.addedMember')
      );
      return;
    }

    if (formProps.values.visitTo && aUser.id === formProps.values.visitTo.id) {
      formProps.setFieldError(
        ReservationConstants.SEARCH_USER,
        t('reservation.label.ReservationBottomForms.visitors.sameWithVisitTo')
      );
      return;
    }

    aUser = await memberService.isValidMember(aUser, workplace, sessionUserInfo);

    /*
    if (ReservationConstants.REGEXP_MOBILE_GLOBAL.test(aUser.mobile)) {
      const globalIndex = aUser.mobile.indexOf('82');
      aUser.mobile = '0' + aUser.mobile.substring(globalIndex+2);
    }
    */

    if (
      !ReservationConstants.REGEXP_MOBILE_VALUE.test(aUser.mobile) &&
      aUser.isValid &&
      sessionUserInfo.id !== aUser.id
    ) {
      history.push(history.location.hash + '#invalidModal');
      aUser.invalidMessage = t('reservation.label.ReservationBottomForms.visitors.incorrectMobile', {
        visitor: aUser.name,
      });
      formProps.setRequirementInvalidModalMessage(
        t('reservation.label.ReservationBottomForms.visitors.incorrectMobile', { visitor: aUser.name })
      );
      formProps.setVisitor(aUser);
      formProps.setShowRequirementInvalidModal(true);
    }

    setSearchUserCond({ ...initCondition });
    setVisitorPhone('');
    if (sessionUserInfo.id !== aUser.id && !aUser.isValid) {
      history.push(history.location.hash + '#invalidModal');
      formProps.setRequirementInvalidModalMessage(t(aUser.invalidMessage || ''));
      formProps.setVisitor(aUser);
      formProps.setShowRequirementInvalidModal(true);
    } else {
      const _visitors: Visitor[] = formProps.values.visitors ? [...formProps.values.visitors] : [];
      const addVisitor: Visitor = {
        name: aUser.name,
        id: aUser.id,
        position: aUser.position,
        companyName: aUser.companyName,
        employeeNumber: aUser.employeeNumber,
        dept: aUser.dept,
        type: aUser.type,
        applyMemberMobilePhone: aUser.mobile ? getNullToEmpty(aUser.mobile).replace(/-/gi, '') : '',
        fromVisitPeriod: formProps.values.fromVisitPeriod,
        toVisitPeriod: formProps.values.toVisitPeriod,
        idpUserId: aUser.idpUserId,
        isValid: aUser.isValid,
        importedGoodsList: [],
      };

      if (sessionUserInfo && aUser.id === sessionUserInfo.id) {
        const visitor = _visitors.find(visitor => visitor.id === sessionUserInfo.id);
        if (!visitor) {
          _visitors.splice(0, 0, addVisitor);
        } else {
          visitor.idpUserId = aUser.idpUserId;
          visitor.isValid = aUser.isValid;
          _visitors.splice(0, 1, visitor);
        }
        if (
          aUser.type !== UserTypeEnum.LGMEMBER ||
          (aUser.type === UserTypeEnum.LGMEMBER &&
            workplace &&
            (workplace.idpYesorno === 'N' || (workplace.idpYesorno === 'Y' && aUser.idpUserId)))
        ) {
          formProps.setFieldValue('isSessionVisitorDisabled', false);
        }
      } else if (aUser.isValid === true) {
        _visitors.push(addVisitor);
      }

      formProps.setFieldValue('visitors', _visitors, checkIsSubmited(formProps));
    }
  };

  const handleUserTypeCondChange = (event: React.MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault();
    setSearchUserCond({ ...initCondition });
    setVisitorPhone('');
    setShowSearchUser(false);
    setShowNoSearchUser(false);
    setShowSearchClearButton(false);
    setShowSearchEnterButton(false);
    formProps.setFieldError(ReservationConstants.SEARCH_USER, undefined);
    if (searchUserType === UserTypeEnum.NONEMEMBER) {
      setVisitToPhoneMask('9999');
      setSearchUserType(UserTypeEnum.MEMBER);
      setVisitorsTitle(t('reservation.label.ReservationBottomForms.visitors.title.member'));
      setHandleUserTitle(t('reservation.label.ReservationBottomForms.visitors.addNoneMember'));
    } else {
      setVisitToPhoneMask('999-9999-9999');
      setSearchUserType(UserTypeEnum.NONEMEMBER);
      setVisitorsTitle(t('reservation.label.ReservationBottomForms.visitors.title.noneMember'));
      setHandleUserTitle(t('reservation.label.ReservationBottomForms.visitors.addMember'));
    }
  };

  const handleUserNameCondChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setShowSearchClearButton(false);
    setShowSearchEnterButton(true);
    setSearchUserCond({ ...searchUserCond, name: event.target.value });
  };

  const handleUserMobileMaskChange = (newState, oldState, userInput) => {
    const newValue: string = newState.value;
    const oldValue: string = oldState.value;
    const regexN = /[^0-9]/;

    if (searchUserType === UserTypeEnum.NONEMEMBER && newValue.length > 3) {
      if (!newValue.startsWith('010')) setVisitToPhoneMask('999-999-9999');
      else setVisitToPhoneMask('999-9999-9999');
    }
    if (
      (userInput === null || oldValue.length < visitToPhoneMask.length) &&
      (userInput !== ' ' || regexN.test(userInput))
    ) {
      return newState;
    } else {
      return oldState;
    }
  };
  const handleUserMobileCondChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setShowSearchClearButton(false);
    setShowSearchEnterButton(true);
    setSearchUserCond({ ...searchUserCond, mobile: event.target.value });
    setVisitorPhone(event.target.value);
  };
  const handleUserEmpTypeCondChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setShowSearchClearButton(false);
    setShowSearchEnterButton(true);
    setSearchUserCond({ ...searchUserCond, position: event.target.value });
  };
  const handleUserCompanyCondChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setShowSearchClearButton(false);
    setShowSearchEnterButton(true);
    setSearchUserCond({ ...searchUserCond, company: event.target.value });
  };

  const handlePurposeTypeChange = (purposeType: string) => {
    formProps.setFieldValue('purpose', purposeType, checkIsSubmited(formProps));
  };

  const handleEntryAreaCodeChange = (entryArea: any) => {
    const _workplace = workplace;
    _workplace.idpAuthorityId = entryArea[0];
    _workplace.entryAreaId = entryArea[1];
    formProps.setFieldValue('entryArea', entryArea, checkIsSubmited(formProps));
  };

  const showPurposeInput = (event: React.MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault();
    setShowPurposeMore(!showPurposeMore);
    setPurposeMore('');
    formProps.setFieldValue('purposeDetail', '', false);
  };

  const handlePurposeMoreChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPurposeMore(event.target.value);
    formProps.setFieldValue('purposeDetail', event.target.value, checkIsSubmited(formProps));
  };
  const handleSendTalkMessageOnoff = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.name === 'sendTalkMessageYesorno') {
      if (event.target.value && event.target.checked) {
        formProps.setFieldValue('sendTalkMessageYesorno', true);
      } else {
        formProps.setFieldValue('sendTalkMessageYesorno', false);
      }
    }
  };
  const handleTermChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.name === 'termPersonal') {
      if (event.target.value && event.target.checked) {
        formProps.setFieldValue('termsId', event.target.value);
      } else {
        formProps.setFieldValue('termsId', undefined);
      }
    }
    formProps.setFieldValue(event.target.name, event.target.checked);
  };
  const handleVaccineChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.name === 'termVaccine') {
      if (event.target.value && event.target.checked) {
        formProps.setFieldValue('termVaccine', true);
      } else {
        formProps.setFieldValue('termVaccine', false);
      }
    }
    formProps.setFieldValue(event.target.name, event.target.checked);
  };
  /* eslint-disable */
  const setIncludedNoneMember = (visitors: Visitor[]): void => {
    if (sessionUserInfo && visitors && visitors.length > 0) {
      if (sessionUserInfo.type !== UserTypeEnum.NONEMEMBER) {
        const isIncludedNoneMember: boolean =
          visitors.findIndex((visitor: Visitor) => {
            return visitor.type === UserTypeEnum.NONEMEMBER;
          }) > -1;

        if (isIncludedNoneMember) {
          formProps.setFieldValue('isIncludedNoneMember', true, checkIsSubmited(formProps));
          setShowTermForNoneMember(true);
          !formProps.values.termNoneMember &&
            formProps.setFieldValue('termNoneMember', false, checkIsSubmited(formProps));
        } else {
          formProps.setFieldValue('isIncludedNoneMember', false, false);
          formProps.setFieldValue('termNoneMember', undefined, checkIsSubmited(formProps));
          setShowTermForNoneMember(false);
        }
      }
    } else {
      formProps.setFieldValue('isIncludedNoneMember', false, false);
      formProps.setFieldValue('termNoneMember', undefined, checkIsSubmited(formProps));
      setShowTermForNoneMember(false);
    }
  };

  useEffect(() => {
    let isExist = false;
    formProps.values.visitors.map((visitor: Visitor, i: number) => {
      if (visitor.id === sessionUserInfo.id) {
        isExist = true;
        return;
      }
    });

    setIncludedNoneMember(formProps.values.visitors);
    setAmIVisitor(isExist);

    const _workplace: WorkplaceVisitInfo = formProps.workplaceVisitInfo ? formProps.workplaceVisitInfo : {};
    if (_workplace.requiredEducation) {
      if (
        policyCompleteState &&
        policyCompleteState.safeEducationComplete === 'Y' &&
        policyCompleteState.securityEducationComplete === 'Y' &&
        policyCompleteState.safeAgreementComplete === 'Y' &&
        policyCompleteState.securityAgreementComplete === 'Y'
      ) {
        formProps.setFieldValue('isPass', true);
      } else {
        if (isExist) {
          formProps.setFieldValue('isPass', false);
        } else {
          formProps.setFieldValue('isPass', true);
        }
      }
    }
  }, [formProps.values.visitors]);

  useEffect(() => {
    if (sessionUserInfo && Object.keys(sessionUserInfo).length > 0) {
      const _visitors = formProps.values.visitors ? [...formProps.values.visitors] : [];
      if (
        _visitors.findIndex((visitor: Visitor) => {
          return visitor.id === sessionUserInfo.id;
        }) < 0
      ) {
        addUser(sessionUserInfo as User, true);
      }
      if (sessionUserInfo.type === UserTypeEnum.NONEMEMBER) {
        setSearchUserCond({ ...initCondition });
        setVisitorPhone('');
        setSearchUserType(sessionUserInfo.type);
        setMaxVisitor(1);
      }

      formProps.setFieldValue('termsAgreeYn', sessionUserInfo.termsAgreeYn);

      // 약관 동의 정보 조회
      if (sessionUserInfo.termsAgreeYn !== 'Y' && sessionUserInfo.type === UserTypeEnum.MEMBER) {
        getTerms(MemberTypeCode.MEM);
      }

      //사용자가 외부회원인 경우, 방문자추가 및 방문자 검색결과없음 메세지 변경
      if (sessionUserInfo.type === UserTypeEnum.MEMBER) {
        setVisitorsTitle(t('reservation.label.ReservationBottomForms.visitors.title.memberForExternalMember'));
        setVisitorsSearchNoResultMessage(
          t('reservation.label.ReservationBottomForms.visitors.noResultForExternalMember')
        );
      }
    }
  }, [sessionUserInfo]);

  /* eslint-disable */
  useEffect(() => {
    formProps.setFieldValue('isSessionVisitorDisabled', true);
    if (formProps.values.sendTalkMessageYesorno === undefined) {
      formProps.setFieldValue('sendTalkMessageYesorno', true);
    }
    if (!workplace) {
      setSearchUserCond({ ...initCondition });
      setVisitorPhone('');
      setSearchUserType(getArrIndexStringValue(memberTypeExceptMe, 1, 'codeId'));
      if (sessionUserInfo.type === UserTypeEnum.MEMBER) {
        setVisitorsTitle(t('reservation.label.ReservationBottomForms.visitors.title.memberForExternalMember'));
      } else {
        setVisitorsTitle(t('reservation.label.ReservationBottomForms.visitors.title.member'));
      }
      setHandleUserTitle(t('reservation.label.ReservationBottomForms.visitors.addNoneMember'));
      setShowPurposeMore(false);
      setPurposeMore('');
      setAvailableGoodsList([]);
      setVisitPurposeList([]);
      setVisitorInfoMessage('');
      setPortSealExceptCodeList([]);
    } else {
      setWorkplaceInfo();
    }
  }, [workplace]);

  /* eslint-enable */

  const setWorkplaceInfo = async () => {
    delete sessionUserInfo.idpUserId;
    setWorkplaceLoading(true);
    const visitors = formProps.values.visitors;

    if (visitors && visitors.length > 0 && visitors[0].id === sessionUserInfo.id) {
      addUser(sessionUserInfo as User, true);
    }
    formProps.setFieldValue('visitPurposeYesorno', workplace.visitPurposeYesorno);
    if (workplace.requiredEducation === 1) {
      await getPolicyCompleteSummary();
    } else {
      formProps.setFieldValue('isPass', true);
    }
    if (workplace.visitPurposeYesorno === 'Y') {
      await getVisitPurpose();
      if (formProps.values.purposeDetail) {
        setPurposeMore(formProps.values.purposeDetail);
        setShowPurposeMore(true);
      }
    }
    if (workplace.visitImportedGoodsYesorno === 'Y') {
      await getVisitImportedGoods();
    } else if (workplace.parkingAbleYesorno === 'Y') {
      setVisitorInfoMessage(t('reservation.label.ReservationBottomForms.visitors.inputInfo.carNumber'));
    }
    formProps.setFieldValue('entryAreaYesorno', workplace.entryAreaYesorno);
    if (workplace.entryAreaYesorno === 'Y') {
      getEntryAreaList();
    }
    if (workplace.vaccinationYesorno === 'Y') {
      formProps.setFieldValue('vaccinationYesorno', 'Y');
      formProps.setFieldValue('termVaccine', false);
    }
    setWorkplaceLoading(false);
  };

  const getVisitPurpose = async () => {
    const visitPurposeCode = await commonCodeService.getCodeByWorkplace(
      workplace.workplaceId,
      CommonCodeService.VISIT_PURPOSE_CODE
    );
    if (visitPurposeCode.length > 0) {
      setVisitPurposeList(visitPurposeCode);
    }
  };

  const getVisitImportedGoods = async () => {
    const importedGoodsCode = await commonCodeService.getCodeByWorkplace(
      workplace.workplaceId,
      CommonCodeService.IMPORTED_GOODS_CODE
    );
    if (importedGoodsCode.length > 0) {
      setAvailableGoodsList(importedGoodsCode);

      if (workplace.visitImportedGoodsYesorno === 'Y' && workplace.parkingAbleYesorno === 'Y') {
        setVisitorInfoMessage(t('reservation.label.ReservationBottomForms.visitors.inputInfo.importedGoodsCarNumber'));
      } else if (workplace.visitImportedGoodsYesorno === 'Y') {
        setVisitorInfoMessage(t('reservation.label.ReservationBottomForms.visitors.inputInfo.importedGoods'));
      }
      const notebook = importedGoodsCode.find(code => code.codeId === ImportedGoodsCodeEnum.NOTEBOOK);
      if (notebook && workplace.portSealExceptYesorno === 'Y') {
        getPortSealExceptCodeList();
      }
    } else {
      setAvailableGoodsList([]);
      if (workplace.parkingAbleYesorno === 'Y') {
        setVisitorInfoMessage(t('reservation.label.ReservationBottomForms.visitors.inputInfo.carNumber'));
      }
    }
  };

  const getPortSealExceptCodeList = async () => {
    const portSealExceptCodeList = await commonCodeService.getCodeByWorkplace(
      workplace.workplaceId,
      CommonCodeService.SEAL_EXCEPTION_IMPORTED_GOODS
    );
    if (portSealExceptCodeList.length > 0) {
      setPortSealExceptCodeList(portSealExceptCodeList);
    }
  };

  const getTerms = async (termsApplyGroupCode: string) => {
    const termInfoResponse = await termService.requestTermInfo(termsApplyGroupCode);
    if (termInfoResponse.successOrNot === 'Y') {
      const termsInfo = termInfoResponse.data as TermsInfo[];
      const thirdTerms = termsInfo.find((terms: TermsInfo) => {
        if (terms.termsTypeCode === TermsTypeCodeEnum.THIRD && terms.lastVersionYn === 'Y') {
          return terms;
        }
      });
      if (thirdTerms) {
        setThirdTerms(thirdTerms);
      }
    }
  };

  const getPolicyCompleteSummary = async () => {
    const policyComplete = await workplaceService.getPolicyCompleteSummary(
      workplace.workplaceId,
      sessionUserInfo.id,
      sessionUserInfo.type
    );

    if (
      policyComplete &&
      policyComplete.safeEducationComplete === 'Y' &&
      policyComplete.securityEducationComplete === 'Y' &&
      policyComplete.safeAgreementComplete === 'Y' &&
      policyComplete.securityAgreementComplete === 'Y' &&
      policyComplete.privacyAgreementComplete === 'Y'
    ) {
      formProps.setFieldValue('isPass', true);
    } else {
      formProps.setFieldValue('isPass', false);
    }

    setPolicyCompleteState(policyComplete);
  };

  const handleVisitorInfoModal = (index: number): void => {
    const visitorDetailInfo = { ...formProps.values.visitors[index] };
    if (sessionUserInfo.memberTypeCode !== MemberTypeCode.NO_MEM) {
      const memberTypeInfo = reservationDetailUtil.getMemberTypeInfo(visitorDetailInfo, sessionUserInfo);

      visitorDetailInfo.badgeType = memberTypeInfo.class;
      visitorDetailInfo.badgeName = t(memberTypeInfo.desc);
    }

    setVisitorIndex(index);
    setVisitorDetailInfo(visitorDetailInfo);
    history.push(history.location.hash + '#visitorDetailInfoModal');
  };

  const onVisitorDetailInfoModalClose = (): void => {
    history.goBack();
  };

  const onVisitorDetailInfoModalConfirm = (visitor: Visitor): void => {
    const visitors = [...formProps.values.visitors];
    visitor.applyMemberMobilePhone = visitor.applyMemberMobilePhone.replace(/-/gi, '');
    visitors[visitorIndex] = visitor;
    formProps.setFieldValue('visitors', visitors);
    onVisitorDetailInfoModalClose();
  };

  const handleThirdTermsModalShow = (): void => {
    if (!showThirdTerms) {
      history.push(history.location.hash + '#thirdTerms');
    } else {
      history.goBack();
    }
    setShowThirdTerms(!showThirdTerms);
  };

  const clearSearchCond = (): void => {
    setSearchUserCond({ ...initCondition });
    setVisitorPhone('');
    setShowSearchClearButton(false);
    formProps.setFieldError(ReservationConstants.SEARCH_USER, undefined);
  };

  /* eslint-disable */
  const handleShowEduAndAgreementModal = async (): Promise<void> => {
    let policyContentsList: PolicyContentsModel[] = [];
    if (!formProps.values.isPass) {
      policyContentsList = await workplaceService.getPolicyAllContents(workplace.workplaceId);
    } else {
      policyContentsList = await workplaceService.getPolicyWorkplaceContents(workplace.workplaceId);
    }

    if (policyContentsList && policyContentsList.length > 0) {
      setPolicyContentsList(policyContentsList);
      setSelectedPolicyContent(policyContentsList[0]);
      setShowEduAndAgreementModal(true);
      history.push(history.location.hash + '#eduAndAgreementModal');
    }
  };
  /* eslint-disable */
  useEffect(() => {
    const location = history.location;
    if (!location.hash.includes('#visitorDetailInfoModal')) {
      setVisitorDetailInfo(null);
    }
    if (!location.hash.includes('#eduAndAgreementModal')) {
      setShowEduAndAgreementModal(false);
    }
  }, [history.location]);
  /* eslint-enable */

  const handleWorkplaceVisitInfo = (workplaceVisitInfo: WorkplaceVisitInfo): void => {
    formProps.setWorkplaceVisitInfo(workplaceVisitInfo);
    formProps.setFieldValue('isPass', workplaceVisitInfo.isPass);
  };

  const handleHideEduAndAgreementModal = async () => {
    await getPolicyCompleteSummary();
    setTimeout(() => {
      history.goBack();
    }, 200);
  };

  const handleKeyPressed = e => {
    if (e.key === 'Enter') {
      searchUsers();
    }
  };

  const handleUploadModal = () => {
    setIsShowExcelUpload(true);
  };

  const handleUploadClose = () => {
    setIsShowExcelUpload(false);
  };

  const handleUploadConfirm = (addNomembers: Visitor[]) => {
    setIsShowExcelUpload(false);
    formProps.setFieldValue('visitors', addNomembers, checkIsSubmited(formProps));
  };

  const getSendTalkMessageLabel = (workplaceVisitInfo: WorkplaceVisitInfo): string | undefined => {
    let returnVal: string | undefined = undefined;

    if (workplaceVisitInfo.requestNotiYesorno) {
      if (workplaceVisitInfo.approvalNotiYesorno) {
        returnVal = t('reservation.label.ReservationBottomForms.sendTalkMessage.bothOnApprovalAndRequest');
      } else {
        returnVal = t('reservation.label.ReservationBottomForms.sendTalkMessage.onlyOnRequest');
      }
    } else if (workplaceVisitInfo.approvalNotiYesorno) {
      returnVal = t('reservation.label.ReservationBottomForms.sendTalkMessage.onlyOnApproval');
    }
    return returnVal;
  };

  const getEntryAreaList = async () => {
    const entryAreaList = await workplaceService.getEntryAreaList(workplace.workplaceId);
    if (entryAreaList.length > 0) {
      setEntryAreaList(entryAreaList);
    }
  };

  return (
    <div className="ReservationBottomForms" id="ReservationBottomForms" data-testid="ReservationBottomForms">
      <Button
        id="back-button"
        onClick={(e: any) => {
          e.stopPropagation();
          e.preventDefault();
          history.goBack();
        }}
      ></Button>
      <div className={workplace ? 'visitorList' : 'visitorList disabled'} id="visitorList" data-testid="visitorList">
        <div>
          <div className="title-main d-inline-block">
            <span>{t('reservation.label.ReservationBottomForms.visitors.title.main')}</span>
            {sessionUserInfo.memberTypeCode == 'EMP' && workplace && workplace.registerNoneMemberYesorno === 'Y' && (
              <div className={'upload-div'}>
                <Button
                  name="upload"
                  className={'upload'}
                  data-testid="upload-button"
                  variant="outline-dark"
                  onClick={handleUploadModal}
                >
                  <Image src={uploadIcon} className={'upload-icon'} />
                  <span>{t('reservation.label.ReservationBottomForms.visitors.button.excelUpload')}</span>
                </Button>
                <ExcelUploadModal
                  formikVal={formProps.values}
                  workplaceInfo={workplace}
                  show={isShowExcelUpload}
                  onClose={handleUploadClose}
                  onAddVisitor={handleUploadConfirm}
                />
              </div>
            )}
          </div>
        </div>
        {formProps.values.isSessionVisitorDisabled &&
          sessionUserInfo &&
          sessionUserInfo.type !== UserTypeEnum.NONEMEMBER &&
          (!formProps.values.visitTo ||
            (formProps.values.visitTo && sessionUserInfo.id !== formProps.values.visitTo.id)) && (
            <ReservationBottomVisitor
              {...{
                visitor: { ...sessionUserInfo, importedGoodsList: [] },
                fromVisitPeriod: formProps.values.fromVisitPeriod,
                toVisitPeriod: formProps.values.toVisitPeriod,
                deleteVisitor: deleteVisitor,
                sessionUser: sessionUserInfo,
                sessionUserDisabled: formProps.values.isSessionVisitorDisabled,
                addUser: addUser,
                index: 0,
                disabled: !workplace,
                visitTo: formProps.values.visitTo,
              }}
            />
          )}
        {formProps.values.visitors &&
          formProps.values.visitors.map((visitor: Visitor, i: number) => {
            if (formProps.values.visitTo && visitor.id === formProps.values.visitTo.id) {
              deleteVisitor(i);
              return;
            }
            if (
              sessionUserInfo.type === UserTypeEnum.NONEMEMBER ||
              (formProps.values.isSessionVisitorDisabled && visitor.id === sessionUserInfo.id)
            )
              return;
            return (
              <ReservationBottomVisitor
                {...{
                  visitor: visitor,
                  fromVisitPeriod: formProps.values.fromVisitPeriod,
                  toVisitPeriod: formProps.values.toVisitPeriod,
                  deleteVisitor: deleteVisitor,
                  sessionUser: sessionUserInfo,
                  sessionUserDisabled: formProps.values.isSessionVisitorDisabled,
                  addUser: addUser,
                  index: i,
                  disabled: !workplace,
                  handleVisitorInfoModal: handleVisitorInfoModal,
                  infoMessage: visitorInfoMessage,
                }}
                key={i}
              />
            );
          })}
        {sessionUserInfo.type === UserTypeEnum.NONEMEMBER &&
          formProps.values.visitors &&
          formProps.values.visitors.length > 0 && (
            <ReservationBottomForNoneMember
              {...{
                visitor: formProps.values.visitors[0],
                fromVisitPeriod: formProps.values.fromVisitPeriod,
                toVisitPeriod: formProps.values.toVisitPeriod,
                deleteVisitor: deleteVisitor,
                sessionUser: sessionUserInfo,
                sessionUserDisabled: formProps.values.isSessionVisitorDisabled,
                addUser: addUser,
                index: 0,
                disabled: !workplace,
                handleVisitorInfoModal: handleVisitorInfoModal,
                infoMessage: visitorInfoMessage,
                setFieldValue: formProps.setFieldValue,
                formProps: formProps,
              }}
            />
          )}
        {visitorDetailInfo && (
          <VisitorDetailInfoModal
            visitor={visitorDetailInfo}
            parkingAbleYesorno={workplace.parkingAbleYesorno}
            portSealExceptYesorno={workplace.portSealExceptYesorno}
            portSealExceptCodeList={portSealExceptCodeList}
            wifiAbleYesorno={workplace.wifiAbleYesorno}
            macAddressYesorno={workplace.macAddressYesorno}
            visitImportedGoodsYesorno={workplace.visitImportedGoodsYesorno}
            getCommonCode={getCommonCode}
            availableGoodsList={availableGoodsList}
            onConfirm={onVisitorDetailInfoModalConfirm}
            onClose={onVisitorDetailInfoModalClose}
            maxVisitPeriod={workplace.maxVisitPeriod}
            sessionUserInfo={sessionUserInfo}
            workplace={workplace}
          ></VisitorDetailInfoModal>
        )}

        {workplace &&
        workplace.registerAccompanyingVisitorYesorno === 'Y' && // 사업장 별 정책을 조회하여 동행자 등록 여부에 따라 본 영역을 show/hide 한다
        formProps.sessionUser &&
        formProps.sessionUser.type !== UserTypeEnum.NONEMEMBER && ( // 방문신청인이 비회원일 경우 동행자 등록 부분을 hide 한다.
            <div
              id="visitorAddArea"
              className={
                workplace
                  ? searchUserType !== UserTypeEnum.NONEMEMBER
                    ? 'state'
                    : 'state noneMember'
                  : 'state disabled'
              }
              data-testid="visitorAddArea"
            >
              <div className="title d-inline-block">
                <span>{visitorsTitle}</span>
                {searchUserType === UserTypeEnum.NONEMEMBER && (
                  <i
                    className="material-icons pointer clearNoneVisitor"
                    data-testid="clearNoneVisitor"
                    onClick={(e: any) => {
                      e.stopPropagation();
                      e.preventDefault();
                      clearSearchCond();
                    }}
                  >
                    close
                  </i>
                )}
                {sessionUserInfo.type === UserTypeEnum.LGMEMBER &&
                  (workplace && (formProps.values.visitors ? formProps.values.visitors.length : 0) < maxVisitor ? (
                    workplace.registerNoneMemberYesorno === 'Y' ? (
                      <a
                        href="#"
                        id="handleUserTypeCondChange"
                        data-testid="handleUserTypeCondChange"
                        className="underline"
                        onClick={handleUserTypeCondChange}
                      >
                        {handleUserTitle}
                      </a>
                    ) : null
                  ) : (
                    <span id="maxVisitor" data-testid="maxVisitor" className="d-inline-block underline">
                      {t('reservation.label.ReservationBottomForms.visitors.maxVisitor')}
                    </span>
                  ))}
              </div>
              <div className="title bottom-line"></div>
              <div className={'visitorAdd'}>
                {(formProps.values.visitors ? formProps.values.visitors.length : 0) < maxVisitor && (
                  <div className="searchUserBar">
                    <InputGroup className={workplace ? '' : 'disabled'}>
                      <InputGroup.Prepend className="d-inline-block">
                        {searchUserType === UserTypeEnum.NONEMEMBER ? (
                          <i className="material-icons searchIcon" data-testid="visitor-add" onClick={searchUsers}>
                            add
                          </i>
                        ) : (
                          <Image
                            src={searchBlack16Icon}
                            onClick={searchUsers}
                            data-testid="visitor-search"
                            className="d-inline-block searchIcon"
                          />
                        )}
                      </InputGroup.Prepend>
                      <div className="form-item">
                        <i></i>
                        <Form.Control
                          id="userNameInput"
                          data-id="userNameInput"
                          className="required d-inline-block"
                          type="text"
                          placeholder={t(ReservationConstants.MSG_CODE_FOR_NAME)}
                          name="userName"
                          value={searchUserCond.name}
                          onChange={handleUserNameCondChange}
                          disabled={workplace ? false : true}
                          onKeyPress={handleKeyPressed}
                          maxLength={ReservationConstants.NAME_MAX_LENGTH}
                        />
                      </div>
                      <div className="form-item">
                        <i></i>
                        {checkInputGroups(VisitorSearchConditionTypeEnum.MOBILE) && (
                          <InputMask
                            mask={visitToPhoneMask}
                            formatChars={{
                              '9': '[0-9]',
                            }}
                            maskChar={null}
                            value={visitorPhone || ''}
                            disabled={workplace ? false : true}
                            beforeMaskedValueChange={handleUserMobileMaskChange}
                            onChange={handleUserMobileCondChange}
                          >
                            {(inputProps: any) => {
                              return (
                                <Form.Control
                                  id="userMobileInput"
                                  data-id="userMobileInput"
                                  className="required d-inline-block"
                                  type="tel"
                                  placeholder={
                                    searchUserType !== UserTypeEnum.NONEMEMBER
                                      ? t(ReservationConstants.MSG_CODE_FOR_MOBILE_MEMBER)
                                      : t(ReservationConstants.MSG_CODE_FOR_MOBILE_NOMEMBER)
                                  }
                                  name="userMobile"
                                  value={inputProps.visitorPhone}
                                  onKeyPress={handleKeyPressed}
                                />
                              );
                            }}
                          </InputMask>
                        )}
                      </div>

                      {checkInputGroups(VisitorSearchConditionTypeEnum.USERTYPE) && (
                        <div className="form-item">
                          <Form.Control
                            id="userTypeInput"
                            data-testid="userTypeInput"
                            className="d-inline-block"
                            type="text"
                            placeholder={t(ReservationConstants.MSG_CODE_FOR_TYPE)}
                            name="userType"
                            value={searchUserCond.position}
                            onChange={handleUserEmpTypeCondChange}
                            disabled={workplace ? false : true}
                            onKeyPress={handleKeyPressed}
                          />
                        </div>
                      )}

                      {checkInputGroups(VisitorSearchConditionTypeEnum.USERCOMPANY) && (
                        <div className="form-item">
                          <i></i>
                          <Form.Control
                            id="userCompanyInput"
                            data-testid="userCompanyInput"
                            className="d-inline-block"
                            type="text"
                            placeholder={t(ReservationConstants.MSG_CODE_FOR_COMPANY)}
                            name="userCompany"
                            value={searchUserCond.company}
                            onChange={handleUserCompanyCondChange}
                            disabled={workplace ? false : true}
                            onKeyPress={handleKeyPressed}
                          />
                        </div>
                      )}

                      <div>
                        {showSearchClearButton && (
                          <Image
                            src={close24Icon}
                            onClick={clearSearchCond}
                            data-testid="clear-search-cond"
                            className="d-inline-block clear-search-cond"
                          />
                        )}
                        {showSearchEnterButton && (
                          <Image
                            src={enter24Icon}
                            onClick={searchUsers}
                            data-testid="enter-search-cond"
                            className={'d-inline-block enter-search-cond ' + searchUserType}
                          />
                        )}
                      </div>
                    </InputGroup>
                  </div>
                )}

                {showSearchUser && workplace && (
                  <div className="searchUserListArea">
                    <OutsideClickHandler
                      onOutsideClick={() => {
                        setShowSearchUser(false);
                      }}
                    >
                      <div data-testid="searchUserList" id="searchUserList" className="searchUserList">
                        {searchedUsers &&
                          searchedUsers.map((user: User, i) => {
                            let userInfo = user.name + ' ';
                            userInfo += user.position || '';
                            if (user.companyName && user.companyName.length > 0) {
                              userInfo += ' / ' + user.companyName + ' ';
                              userInfo += user.dept || '';
                            }
                            if (user.emailAddress && user.emailAddress.length > 0) {
                              userInfo += ' / ' + user.emailAddress;
                            }

                            return (
                              <Dropdown.Item
                                key={i}
                                eventKey={user.id}
                                onSelect={addUserBySearch}
                                className="searchUser"
                                id="searchUser"
                                data-testid="searchUser"
                              >
                                {userInfo}
                              </Dropdown.Item>
                            );
                          })}
                      </div>
                    </OutsideClickHandler>
                  </div>
                )}

                {showNoSearchUser && (
                  <div>
                    <OutsideClickHandler
                      onOutsideClick={() => {
                        setShowNoSearchUser(false);
                      }}
                    >
                      <div data-testid="searchUserList" id="searchUserList" className="searchUserList">
                        {searchedUsers && searchedUsers.length === 0 && (
                          <Dropdown.Item
                            className="noResult"
                            id="searchUserNoResult"
                            data-testid="searchUserNoResult"
                            onSelect={e => {
                              setShowNoSearchUser(false);
                            }}
                          >
                            <p className="d-inline-block">
                              <strong>{visitorsSearchNoResultMessage}</strong>
                            </p>
                          </Dropdown.Item>
                        )}
                      </div>{' '}
                    </OutsideClickHandler>
                  </div>
                )}

                {formProps.errors.searchUser && (
                  <span data-testid="searchUserValidationMessage" className={'text-danger'}>
                    {formProps.errors.searchUser}
                  </span>
                )}
                {formProps.values.isSubmited && formProps.errors.visitors && (
                  <div>
                    <span data-testid="visitorsValidationMessage" className={'text-danger'}>
                      {formProps.errors.visitors === 'required' &&
                        t(ReservationConstants.MSG_CODE_REQUIRED_FORMAT, {
                          0: t('reservation.label.ReservationBottomForms.visitors.name'),
                        })}
                    </span>
                  </div>
                )}
                <Button
                  className="visitorAddBtn"
                  variant="light"
                  onClick={(e: any) => {
                    e.stopPropagation();
                    e.preventDefault();
                    searchUsers();
                  }}
                >
                  {t('reservation.label.ReservationBottomForms.visitors.button.register')}
                </Button>
              </div>
            </div>
          )}
      </div>
      {workplace && workplace.visitPurposeYesorno === 'Y' && (
        <div className="visitPurpose" id="visitPurposeArea" data-testid="visitPurposeArea">
          <div>
            <div className="title-main d-inline-block">
              <span>{t('reservation.label.ReservationBottomForms.purpose.title')}</span>
            </div>
          </div>
          {visitPurposeList.length > 0 && (
            <ButtonToolbar className="d-inline">
              <ToggleButtonGroup
                type="radio"
                id="visitPurposeTypeChange"
                className="visitPurposeTypeChange d-inline"
                onChange={handlePurposeTypeChange}
                name="purpose-session"
                value={formProps.values.purpose}
              >
                {visitPurposeList.map(purpose => {
                  return (
                    <ToggleButton
                      key={purpose.codeId}
                      data-testid="purpose-session"
                      variant="outline-dark"
                      className="mr-2"
                      value={purpose.codeId}
                      name="purpose-session"
                      type="radio"
                    >
                      {purpose.codeName}
                    </ToggleButton>
                  );
                })}
              </ToggleButtonGroup>
            </ButtonToolbar>
          )}
          <a href="#" data-testid="purposeDetailAlink" className="underline d-inline-block" onClick={showPurposeInput}>
            {t('reservation.label.ReservationBottomForms.purpose.more')}
          </a>
          {formProps.values.isSubmited && formProps.errors.purpose && (
            <div>
              <span data-testid="purposeValidationMessage" className={'text-danger'}>
                {formProps.errors.purpose === 'required' &&
                  t(ReservationConstants.MSG_CODE_REQUIRED_FORMAT, {
                    0: t('reservation.label.ReservationBottomForms.purpose.name'),
                  })}
              </span>
            </div>
          )}
          {showPurposeMore && (
            <Form.Control
              type="text"
              name="purposeMore"
              value={purposeMore}
              onChange={handlePurposeMoreChange}
              data-testid="purposeDetail"
              maxLength={ReservationConstants.PURPOSE_DETAIL_MAX_LENGTH}
              placeholder={t('reservation.label.ReservationBottomForms.purpose.exception.max')}
            />
          )}
          {formProps.values.isSubmited && formProps.errors.purposeDetail && (
            <span data-testid="purposeDetailValidationMessage" className={'text-danger'}>
              {t(formProps.errors.purposeDetail)}
            </span>
          )}
        </div>
      )}
      {workplace && workplace.entryAreaYesorno === 'Y' && (
        <div className="workplaceEntryArea" id="workplaceEntryArea" data-testid="workplaceEntryArea">
          <div>
            <div className="title-main d-inline-block">
              <span>{t('reservation.label.ReservationBottomForms.entryArea.name')}</span>
            </div>
          </div>
          {entryAreaList.length > 0 && (
            <ButtonToolbar className="d-inline">
              <ToggleButtonGroup
                id="workplaceEntryAreaChange"
                className="workplaceEntryAreaChange d-inline"
                onChange={handleEntryAreaCodeChange}
                name="entryArea"
                value={formProps.values.entryArea}
                type="checkbox"
              >
                {entryAreaList.map(entryArea => {
                  return (
                    <ToggleButton
                      key={entryArea.entryAreaId}
                      data-testid="entryArea"
                      variant="outline-dark"
                      className="mr-2"
                      value={entryArea}
                      name="entryArea"
                      type="checkbox"
                      checked={true}
                    >
                      {localStorage.getItem('lang') === 'ko' ? entryArea.entryAreaKrName : entryArea.entryAreaEnName}
                    </ToggleButton>
                  );
                })}
              </ToggleButtonGroup>
            </ButtonToolbar>
          )}
          {formProps.values.isSubmited && formProps.errors.entryArea && (
            <div>
              <span data-testid="purposeValidationMessage" className={'text-danger'}>
                {formProps.errors.entryArea === 'required' &&
                  t(ReservationConstants.MSG_CODE_REQUIRED_FORMAT, {
                    0: t('reservation.label.ReservationBottomForms.entryArea.name'),
                  })}
              </span>
            </div>
          )}
        </div>
      )}
      <div className="checkGroup">
        {showTermForNoneMember && (
          <Form.Check
            custom
            id="termNoneMember"
            type="checkbox"
            name="termNoneMember"
            label={t('reservation.label.ReservationBottomForms.terms.noneMember.content')}
            onChange={handleTermChange}
            className="fontRed"
            checked={formProps.values.termNoneMember ? true : false}
          />
        )}

        {formProps.values.isSubmited && showTermForNoneMember && formProps.errors.termNoneMember && (
          <div>
            <span data-testid="termNoneMemberValidationMessage" className={'text-danger'}>
              {formProps.errors.termNoneMember === 'required' &&
                t(ReservationConstants.MSG_CODE_REQUIRED_FORMAT, {
                  0: t('reservation.label.ReservationBottomForms.terms.noneMember.name'),
                })}
            </span>
          </div>
        )}

        {workplace && workplace.vaccinationYesorno === 'Y' && (
          <Form.Check
            custom
            id="termVaccine"
            type="checkbox"
            name="termVaccine"
            label={t('reservation.label.ReservationBottomForms.terms.vaccination.content')}
            onChange={handleVaccineChange}
            className="fontRed"
            checked={formProps.values.termVaccine ? true : false}
          />
        )}

        {formProps.values.isSubmited && formProps.errors.termVaccine && (
          <div>
            <span data-testid="termVaccineValidationMessage" className={'text-danger'}>
              {formProps.errors.termVaccine === 'required' &&
                t(ReservationConstants.MSG_CODE_REQUIRED_FORMAT, {
                  0: t('reservation.label.ReservationBottomForms.terms.vaccination.name'),
                })}
            </span>
          </div>
        )}

        {sessionUserInfo.type === UserTypeEnum.MEMBER && sessionUserInfo.termsAgreeYn !== 'Y' && (
          <div>
            {' '}
            <Form.Check
              custom
              id="termPersonal"
              type="checkbox"
              name="termPersonal"
              label={t('reservation.label.ReservationBottomForms.terms.personal.content')}
              onChange={handleTermChange}
              value={thirdTerms?.termsId}
              className="d-inline-block"
              checked={formProps.values.termPersonal ? true : false}
            />
            <a
              id="termPersonalDetailButton"
              data-testid="termPersonalDetailButton"
              href="#"
              className="underline d-inline-block"
              onClick={(e: any) => {
                e.preventDefault();
                handleThirdTermsModalShow();
              }}
            >
              {t('reservation.label.ReservationBottomForms.terms.personal.more')}
            </a>
            {showThirdTerms && (
              <TermsAcceptionModal term={thirdTerms} onHide={handleThirdTermsModalShow}></TermsAcceptionModal>
            )}
            {formProps.values.isSubmited && formProps.errors.termPersonal && (
              <div>
                <span data-testid="termPersonalValidationMessage" className={'text-danger'}>
                  {t(ReservationConstants.MSG_CODE_REQUIRED_FORMAT, {
                    0: t('reservation.label.ReservationBottomForms.terms.personal.name'),
                  })}
                </span>
              </div>
            )}
          </div>
        )}
        {formProps.workplaceVisitInfo &&
          (formProps.workplaceVisitInfo.requestNotiYesorno || formProps.workplaceVisitInfo.approvalNotiYesorno) && (
            <div>
              <Form.Check
                custom
                id="sendTalkMessageYesorno"
                type="checkbox"
                name="sendTalkMessageYesorno"
                label={getSendTalkMessageLabel(formProps.workplaceVisitInfo)}
                onChange={handleSendTalkMessageOnoff}
                className="d-inline-block"
                checked={formProps.values.sendTalkMessageYesorno ? true : false}
              />
            </div>
          )}
      </div>
      <div>
        {formProps.workplaceVisitInfo &&
          formProps.workplaceVisitInfo.requiredEducation &&
          formProps.values.isPass !== undefined &&
          amIVisitor && (
            <div className="button" id="requiredEducation" data-testid="requiredEducation">
              <Button
                name="save"
                id="save-button"
                variant={
                  formProps.workplaceVisitInfo && formProps.values.isPass === true
                    ? 'outline-secondary'
                    : 'outline-danger'
                }
                className="bold"
                onClick={handleShowEduAndAgreementModal}
              >
                <span>{t('reservation.label.ReservationBottomRight.educationAgreement.button')}</span>
                <i className="material-icons pointer">chevron_right</i>
              </Button>
              {formProps.workplaceVisitInfo &&
              formProps.workplaceVisitInfo.requiredEducation &&
              formProps.values.isPass === true ? (
                <span className="educationState fontGreen">
                  {' '}
                  <i className="material-icons">done</i>
                  {t('reservation.label.ReservationBottomRight.educationAgreement.done')}
                </span>
              ) : (
                <span className="educationState fontRed">
                  {' '}
                  <i className="material-icons">warning</i>
                  {t('reservation.label.ReservationBottomRight.educationAgreement.alert')}
                </span>
              )}
              <ReservationEduAndAgreementModal
                {...{
                  reservation: formProps.values,
                  user: sessionUserInfo,
                  policyContents: policyContentsList,
                  initPolicyContent: selectedPolicyContent,
                  workplaceVisitInfo: formProps.workplaceVisitInfo,
                  setWorkplaceVisitInfo: handleWorkplaceVisitInfo,
                  show: showEduAndAgreementModal,
                  onHide: handleHideEduAndAgreementModal,
                  setLoading: formProps.setLoading,
                }}
              />
            </div>
          )}
      </div>
      <ReservationBottomButton {...formProps} />
    </div>
  );
};

export default ReservationBottomForms;
